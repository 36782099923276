<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <Loading />
    <v-container fluid id="sub-bar">
      <v-row>
        <v-col>
          <div id="lbl-screen-name">
            {{ $t("label.lbl_WorkHistoryUpdate") }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- 検索項目 -->
    <v-container fluid>
      <v-row class="search-row">
        <div class="search-autocomplete-product first-search-item">
          <!-- 品番 -->
          <v-text-field
            dense
            readonly
            v-model="product"
            :label="$t('label.lbl_productCode')"
            class="txt-single"
            persistent-hint
            margin-bottom="0"
            pa-3
          ></v-text-field>
        </div>
        <!-- 空白 -->
        <span class="item-spacer" style="float: left">&nbsp;</span>
        <!-- 付帯品番 -->
        <div class="search-autocomplete-product">
          <v-text-field
            dense
            readonly
            v-model="accessoryProduct"
            :label="$t('label.lbl_accessoryProduct')"
            class="txt-single"
            persistent-hint
            margin-bottom="0"
            pa-3
          ></v-text-field>
        </div>
        <!-- 空白 -->
        <span class="item-spacer" style="float: left">&nbsp;</span>
        <!-- 品名 -->
        <div class="search-autocomplete-product">
          <v-text-field
            dense
            readonly
            v-model="itemName"
            :label="$t('label.lbl_productCodeName')"
            class="txt-single"
            persistent-hint
            margin-bottom="0"
            pa-3
          ></v-text-field>
        </div>
        <v-row> </v-row>
      </v-row>
    </v-container>

    <!-- データテーブル -->
    <v-form ref="editedList" lazy-validation>
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          disable-filtering
          :options.sync="sortOptions"
          disable-pagination
          height="530px"
          :hide-default-footer="true"
        >
          <!-- ヘッダーロット -->
          <template v-slot:[`header.lot`]="{ header }">
            <v-row>
              <v-col>
                <div>{{ header.text }}</div>
                <div>
                  {{
                    $t("label.lbl_dueDate") +
                    "／" +
                    $t("label.lbl_lotSubkey1") +
                    "／" +
                    $t("label.lbl_lotSubkey2")
                  }}
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.lot`]="{ item }">
            <!-- ロット／S/N -->
            {{ item.lot }}<br />
            {{ item.dueDate }}
          </template>
          <template v-slot:[`item.achieveQty`]="{ item, index }">
            <!-- 実績数-->
            <div id="table-actualQuantity" v-if="item.deleteFlg == 1">
              {{ item.achieveQty }}
            </div>
            <div v-else>
              <v-text-field
                class="input-number"
                dense
                outlined
                v-model="item.achieveQty"
                @click="clickPop(item.achieveQty)"
                @change="changeNum(item, index)"
                :rules="[rules.isNumber]"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'achieveQtyCheck' + index"
                @keydown.enter.prevent="onKeyDown"
              />
            </div>
          </template>
        </v-data-table>

        <div class="text-popBtn pt-2">
          <v-btn class="other-btn" @click="close" style="float: right">
            <!-- 閉じるボタン -->
            {{ $t("btn.btn_close") }}
          </v-btn>
          <v-btn
            class="post-btn"
            @click="resarvationDelete"
            style="float: right"
            :disabled="btnFlg"
          >
            <!-- 更新登録ボタン -->
            {{ $t("btn.btn_updateInsert") }}
          </v-btn>
        </div>
      </v-container>
    </v-form>

    <ConfirmDialog
      :isShow.sync="confirmDialog.isOpen"
      :message="confirmDialog.message"
      :okAction="deleteStock"
      :redMessage="confirmDialog.redMessage"
      :screenFlag="confirmDialog.screenFlag"
      :changeFlag="confirmDialog.changeFlag"
    />

    <SimpleDialog
      :isShow.sync="infoDialogIsOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :redMessage="infoDialog.redMessage"
      :screenFlag="infoDialog.screenFlag"
      :firstPageFlag="infoDialog.firstPageFlag"
    />
  </div>
</template>

<script>
import Loading from "@/components/loading";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import SimpleDialog from "@/components/SimpleDialog";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import ConfirmDialog from "@/components/ConfirmDialog";
import { i18n } from "../../lang/lang.js";
export default {
  name: "WorkHistoryUpdate",
  components: {
    ConfirmDialog,
    SimpleDialog,
    Loading,
  },
  // 親画面から渡される値
  props: {
    workHistoryInfo: { type: Object, default: null },
    isOpenDetailDialog: { type: Boolean, default: false },
    // okAction: {
    //   type: Function,
    //   required: false,
    // },
  },
  data: () => ({
    // ロット
    lot: "",
    // 品番/品名
    product: "",
    //付帯品番
    accessoryProduct: "",
    //品名
    itemName: "",
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
    },
    // メッセージダイアログ
    infoDialog: {
      title: "",
      message: "",
      firstPageFlag: false,
    },
    infoDialogIsOpen: false,
    // 編集行
    editedIndex: "",
    // ソート
    sortItem: "",
    sortOptions: {},
    sortLocaMap: {},
    btnFlg: false,
    updateFlg: false,
    // ヘッダ
    headerItems: [
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // ロケ
      {
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 管理No
      {
        text: i18n.tc("label.lbl_managementNo"),
        value: "ManagementNo",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 実績数
      {
        text: i18n.tc("label.lbl_achieveQty"),
        value: "achieveQty",
        width: "4%",
        align: "right",
        sortable: false,
      },
    ],
    inputList: [],
    // 更新リスト
    updateList: [],
    rules: {
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
    },
  }),

  methods: {
    /**
     * 確認ダイアログ接続
     */
    resarvationDelete() {
      let cnt = 0;
      let fixCnt = 0;
      if (this.inputList.length > 0) {
        // 明細部、入力チェック実施
        for (var i = 0; i < this.inputList.length; i++) {
          var checkQtyNum = this.inputList[i].achieveQty;
          var oriQtyNum = this.inputList[i].oriAchieveQty;

          if (checkQtyNum != oriQtyNum) {
            // 修正したレコードを数える
            fixCnt = i + 1;
          }
          if (this.inputList[i].achieveQty == "") {
            // 実績数が未入力時
            cnt = i + 1;
          } else if (isNaN(Number(commonUtil.zen2han(checkQtyNum).replaceAll(",", "")))) {
            // 実績数が数値でない時
            cnt = i + 1;
          }
        }
        // 明細部、入力エラーなし（カウント0）の場合、確認メッセージ表示
        if (cnt == 0 && fixCnt > 0) {
          this.confirmDialog.screenFlag = false;
          this.confirmDialog.changeFlag = true;
          this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
            "修正内容を登録",
          ]);
          this.confirmDialog.isOpen = true;
        } else if (fixCnt == 0) {
          // 修正したレコードがない場合
          this.infoDialogIsOpen = true;
          this.infoDialog.message = messsageUtil.getMessage("P-RCV-006_001_E");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.firstPageFlag = true;
        }
      } else {
        // 処理なし
      }
    },

    /**
     * 入力カンマ処理 実績数
     */ clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },
    onKeyDown() {},
    changeNum(val, index) {
      if (val.achieveQty == null) {
        // 正しい値でない場合、空を設定する
        val.achieveQty = "";
      } else {
        // 数字が入力されている場合、カンマ処理を実施
        var achieveQty = commonUtil.zen2han(val.achieveQty).replaceAll(",", "");
        achieveQty = achieveQty.replace(/[^0-9]/gi, "");
        if (achieveQty.length > 9) {
          achieveQty = achieveQty.substring(0, 9);
        }
        if (!isNaN(Number(achieveQty))) {
          this.inputList[index].achieveQty = commonUtil.formatToCurrency(Number(achieveQty));
        }
      }
    },

    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.getOpeHistList();
      if (this.workHistoryInfo.status == "0") {
        if (this.workHistoryInfo.procCd != "200") {
          this.btnFlg = false;
        } else {
          this.btnFlg = true;
        }
      } else {
        this.btnFlg = true;
      }
    },

    /**
     * 閉じる処理
     */
    close() {
      this.$emit("update:isOpenDetailDialog", false);
      // this.okAction(true);
    },

    /**
     * 工程実績GETAPI接続
     */
    getOpeHistList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      if (this.workHistoryInfo.checkflg == "true") {
        this.inputList = [];
        // ヘッダー品番/品名、設定
        this.product = this.workHistoryInfo.itemCd.substring(0, 50).trim();
        //付帯品番
        this.accessoryProduct = this.workHistoryInfo.itemCd.slice(-50).trim();
        //品名
        this.itemName = this.workHistoryInfo.itemName;
        // ローディング画面表示OFF
        this.loadingCounter = 0;
      } else {
        const config = this.$httpClient.createGetApiRequestConfig();

        // 営業所SID
        config.params.officeSid = sessionStorage.getItem("sales_office_sid");
        // 取引先SID
        config.params.clientSid = this.workHistoryInfo.suppliersSelected;
        // 工程コード
        config.params.procCd = this.workHistoryInfo.procCd;
        // 品番
        config.params.itemCd = this.workHistoryInfo.itemCd;
        // 入出区分(固定)
        config.params.inOutDiv = "01";
        // if (this.workHistoryInfo.lotNo != null && this.workHistoryInfo.lotNo.trim() != "") {
        //   // ロット
        //   config.params.lotNo = this.workHistoryInfo.lotNo;
        // }
        // from日時
        config.params.fromYyyymmddHhmmss = dateTimeHelper.convertUTC(
          this.workHistoryInfo.scheduleDate
        );
        // to日時
        var arr = this.workHistoryInfo.scheduleDate + " 23:59:59.999";
        config.params.toYyyymmddHhmmss = dateTimeHelper.convertUTC(arr);
        if (this.workHistoryInfo.inListSubSid) {
          // 入荷伝票No
          config.params.inListSubSid = this.workHistoryInfo.inListSubSid;
        }

        if (this.workHistoryInfo.outListSubSid) {
          // 出荷伝票No
          config.params.outListSubSid = this.workHistoryInfo.outListSubSid;
        }

        // ソート
        if (this.sortItem != "") {
          config.params.reqComSortItem = this.sortItem;
        } else {
          config.params.reqComSortItem =
            "item_manage_no desc, update_datetime desc, ope_hist_sid desc";
        }

        // console.debug("getOpeHistList() Config", config);
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_OPEHIST, config)
            .then((response) => {
              // console.debug("getOpeHistList() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                const list = [];
                jsonData.resIdv.opeHists.forEach((row) => {
                  let stock = String(commonUtil.formatToCurrency(Number(row.stock)));
                  let lotNoObj = commonUtil.convertLotNo(row.lotNo);
                  list.push({
                    // 品番
                    itemCd: row.itemCd,
                    // 品名
                    itemName: row.itemName,
                    // ロット
                    lot: lotNoObj.lot,
                    dueDate: lotNoObj.dueDate,
                    // ロケ
                    location: row.locationNo,
                    // 管理No
                    ManagementNo: row.itemManageNo,
                    // 実績数
                    achieveQty: stock,
                    // 実績数
                    oriAchieveQty: stock,
                    // 削除フラグ
                    deleteFlg: row.deleteFlg,
                    // 工程実績SID
                    opeHistSid: row.opeHistSid,
                    // 更新日時
                    updateDatetime: row.updateDatetime,
                  });
                  this.sortLocaMap["location"] = "location_sid";
                });
                this.inputList = list;

                // ヘッダー品番/品名、設定
                this.product = this.workHistoryInfo.itemCd.substring(0, 50).trim();
                //付帯品番
                this.accessoryProduct = this.workHistoryInfo.itemCd.slice(-50).trim();
                //品名
                this.itemName = this.workHistoryInfo.itemName;
                resolve(response);
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialogIsOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialogIsOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    /**
     * 工程実績POSTAPI接続
     */
    deleteStock() {
      // ローディング画面表示ON

      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_COM_006;
      // 修正ﾘｽﾄ
      var bodyList = [];
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].achieveQty != this.inputList[i].oriAchieveQty) {
          var inputMap = {};
          // 工程実績SID
          inputMap.opeHistSid = this.inputList[i].opeHistSid;
          // 工程コード
          inputMap.procCd = this.workHistoryInfo.procCd;
          // 修正数量
          inputMap.afterStock = this.inputList[i].achieveQty.replaceAll(",", "");
          // 更新日時
          inputMap.updateDatetime = this.inputList[i].updateDatetime;
          // 操作画面名対応完了後、活性化
          inputMap.opeDispName = this.workHistoryInfo.screenId;
          bodyList.push(inputMap);
        }
      }

      body.reqIdv.opeHists = bodyList;
      // console.debug("deleteStock() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_OPEHIST, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("deleteStock() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialogIsOpen = true;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              this.updateFlg = true;
              this.getOpeHistList();
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialogIsOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialogIsOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    reload() {},
  },
  computed: {},
  watch: {
    infoDialogIsOpen: function (newValue) {
      if (!newValue) {
        if (this.updateFlg) {
          //再検索
          this.$emit("click");
        }
        this.close();
      }
    },
    isOpenDetailDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);

        if (items.sortBy.length < 1) {
          this.sortItem = "item_manage_no desc, update_datetime desc, ope_hist_sid desc";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          if (sortBy == "location") {
            sortBy = this.sortLocaMap["location"];
          }
          this.sortItem =
            "item_manage_no desc, update_datetime desc, ope_hist_sid desc, " +
            sortBy +
            " " +
            ascdesc;
          this.getOpeHistList();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.text-popBtn {
  padding-bottom: 30px;
}

.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

// ツールチップ ...(省略)設定
.text-overflow {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-row {
  pointer-events: none;
}

.search-autocomplete-product {
  width: 20rem;
}
</style>
