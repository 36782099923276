<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_receivingHistory" />

      <v-container fluid>
        <v-row class="search-row">
          <div class="search-autocomplete first-search-item">
            <!-- 取引先-->
            <div v-if="suppliersSelected.length > 20">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div style="height: 20px" v-bind="attrs" v-on="on">
                    <v-text-field
                      dense
                      readonly
                      v-model="suppliersSelected"
                      :label="$t('label.lbl_supplier')"
                      class="txt-single"
                      persistent-hint
                      margin-bottom="0"
                      pa-3
                    ></v-text-field>
                  </div>
                </template>
                <span>{{ suppliersSelected }} </span>
              </v-tooltip>
            </div>
            <div v-else>
              <div>
                <v-text-field
                  dense
                  readonly
                  v-model="suppliersSelected"
                  :label="$t('label.lbl_supplier')"
                  class="txt-single"
                  persistent-hint
                  margin-bottom="0"
                  pa-3
                ></v-text-field>
              </div>
            </div>
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <div class="date-style">
            <!-- 入荷予定日-->
            <v-text-field
              readonly
              dense
              v-model="arrivalScheduleDate"
              :label="$t('label.lbl_arrivalScheduleDate')"
              class="txt-single"
            ></v-text-field>
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <div class="search-autocomplete first-search-item">
            <v-autocomplete
              dense
              v-model="workingStatus"
              :items="workingStatusList"
              :label="$t('label.lbl_status')"
              class="txt-singles"
              persistent-hint
              margin-bottom="0"
              pa-3
              @change="
                () => {
                  if (
                    0 <
                    workingStatusList.filter((element) => {
                      return element.value === workingStatus;
                    }).length
                  ) {
                    this.page = 1;
                    getEntHistoryList();
                  }
                }
              "
            >
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size"> {{ data.item.text }} </span>
              </template>
            </v-autocomplete>
          </div>
        </v-row>

        <!--ボタン領域-->
        <v-row>
          <div class="search-row-exeBtn">
            <!--戻るボタン領域-->
            <div class="btn-search-area" style="float: left">
              <!-- 戻るボタン-->
              <v-btn color="primary" class="other-btn" v-on:click="backbtn">
                {{ $t("btn.btn_back") }}
              </v-btn>
            </div>

            <div class="btn-area">
              <!--右側ボタン領域-->
              <v-btn class="post-btn" @click="rcvAchAdd()">
                <!--完了ボタン-->
                {{ $t("btn.btn_rcvCom") }}
              </v-btn>
            </div>
          </div>
        </v-row>

        <v-row>
          <div class="display-search-result-row">
            <!--検索結果表示-->
            <div class="text-label2" style="float: left">
              <span
                >{{ $t("label.lbl_totalDetail") }}：{{ totalCount
                }}{{ $t("label.lbl_number") }}</span
              >
            </div>
            <!-- 空白 -->
            <span class="item-historySpacer" style="float: left">&nbsp;</span>
            <div class="text-historylabel" style="float: left">
              {{ $t("label.lbl_receivingHistory1") }}
              <br />
              <span class="item-spacer" style="float: left">&nbsp;</span>

              {{ $t("label.lbl_receivingHistory2") }}
            </div>
            <!--表示件数切替部分-->
            <div style="float: right">
              <!--表示件数-->
              <div class="text-label2" style="clear: both; float: left">
                {{ $t("label.lbl_itemsPerPage") }}
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!--表示件数切替ボタン-->
              <div style="float: left; padding-top: 10px">
                <v-btn-toggle
                  v-model="itemsPerPageBtn"
                  color="blue darken-2"
                  class="toggle black--text"
                  right
                >
                  <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                    {{ 10 }}
                  </v-btn>
                  <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                    {{ 50 }}
                  </v-btn>
                  <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                    {{ 100 }}
                  </v-btn>
                </v-btn-toggle>
              </div>
            </div>
          </div>
        </v-row>
      </v-container>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :options.sync="sortOptions"
          :hide-default-footer="true"
          height="630px"
        >
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item, index }">
            <input
              type="checkbox"
              v-model="item.check"
              @click="checkBoxList(item, index)"
              style="transform: scale(2)"
            />
          </template>

          <!-- ヘッダー部分 -->
          <template v-slot:[`header.No`]="{ header }">
            <!-- 入荷予定No -->
            <div>
              {{ $t("label.lbl_recieveQty") }}<br />
              No
              {{ header.No }}
            </div>
          </template>

          <template v-slot:[`header.arrivalScheduleDate`]="{ header }">
            <!-- 予定日 -->
            <div>
              <br />
              {{ $t("label.lbl_schDate") }}
              {{ header.arrivalScheduleDate }}
            </div>
          </template>

          <template v-slot:[`header.inScheduleQuantity`]="{ header }">
            <!-- 予定数 -->
            <div>
              <br />
              {{ $t("label.lbl_scheduleQty") }}
              {{ header.inScheduleQuantity }}
            </div>
          </template>
          <template v-slot:[`header.arrivalFrom`]="{ header }">
            <!-- 入荷元 -->
            <div>
              <br />
              {{ $t("label.lbl_arrival") }}
              {{ header.arrivalFrom }}
            </div>
          </template>

          <template v-slot:[`header.product`]="{ header }">
            <!-- 品番/品名 -->
            <div>
              <br />
              {{ $t("label.lbl_productCnCd") }}
              {{ header.product }}
            </div>
          </template>

          <template v-slot:[`header.lot`]="{ header }">
            <!-- ロット -->
            <v-row>
              <v-col>
                <div>{{ header.text }}</div>
                <div>
                  {{
                    $t("label.lbl_dueDate") +
                    "／" +
                    $t("label.lbl_lotSubkey1") +
                    "／" +
                    $t("label.lbl_lotSubkey2")
                  }}
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.qualityDivName`]="{ header }">
            <!-- 品質区分 -->
            <div>
              <br />
              {{ $t("label.lbl_arrivalStatus") }}
              {{ header.qualityDivName }}
            </div>
          </template>
          <template v-slot:[`header.processDiv`]="{ header }">
            <!-- 処理区分 -->
            <div>
              <br />
              {{ $t("label.lbl_processingdivision") }}
              {{ header.processDiv }}
            </div>
          </template>
          <template v-slot:[`header.productQty`]="{ header }">
            <!-- 予定数実績数 -->
            <div>
              {{ $t("label.lbl_scheduleQty") }}<br />{{ $t("label.lbl_achieveQty") }}
              {{ header.productQty }}
            </div>
          </template>

          <template v-slot:[`header.inLocationNo`]="{ header }">
            <!-- 格納ロケ -->
            <div>
              <br />
              {{ $t("label.lbl_Storagelocation") }}
              {{ header.inLocationNo }}
            </div>
          </template>

          <template v-slot:[`header.status`]="{ header }">
            <!-- 入庫実績ステータス -->
            {{ $t("label.lbl_arrivalacheivement") }}<br />
            {{ $t("label.lbl_status") }}
            {{ header.status }}
          </template>

          <template v-slot:[`header.startTime`]="{ header }">
            <!-- 開始時刻 -->
            <div>
              <br />
              {{ $t("label.lbl_rcvTime") }}
              {{ header.startTime }}
            </div>
          </template>

          <template v-slot:[`header.inEndTime`]="{ header }">
            <!-- 終了時刻 -->
            <div>
              <br />
              {{ $t("label.lbl_endTime") }}
              {{ header.inEndTime }}
            </div>
          </template>

          <template v-slot:[`header.wareLocationNo`]="{ header }">
            <!-- 格納ロケ -->
            <div>
              <br />
              {{ $t("label.lbl_Storagelocation") }}
              {{ header.wareLocationNo }}
            </div>
          </template>

          <template v-slot:[`header.manager`]="{ header }">
            <!-- 担当 -->
            <div>
              <br />
              {{ $t("label.lbl_popDestinationNm") }}
              {{ header.manager }}
            </div>
          </template>
          <!-- * -->

          <!-- アイテム部分 -->
          <!-- 入荷予定No -->
          <template v-slot:[`item.No`]="{ item }">
            <div class="listNo-style">{{ item.No }}</div>
          </template>
          <template v-slot:[`item.product`]="{ item }">
            <div v-if="item.itemName != null">
              <!-- 品番／品名  改行&ツールチップ -->
              <div v-if="item.itemName.length > 9 || item.itemCd.length > 9">
                <!-- 品名が9文字以上だった場合ツールチップで表示 -->
                <div v-if="item.incidental">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-truncate" style="max-width: 150px" v-bind="attrs" v-on="on">
                        {{ item.itemCd }}<br />
                        {{ item.incidental }}<br />
                        {{ item.itemName }}
                      </div>
                    </template>
                    <span
                      >{{ item.itemCd }}<br />{{ item.incidental }}<br />{{ item.itemName }}</span
                    >
                  </v-tooltip>
                </div>
                <div v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-truncate" style="max-width: 150px" v-bind="attrs" v-on="on">
                        {{ item.itemCd }}<br />
                        {{ item.itemName }}
                      </div>
                    </template>
                    <span>{{ item.itemCd }}<br />{{ item.itemName }}</span>
                  </v-tooltip>
                </div>
              </div>
              <div v-else>
                <!-- 品名が9文字以下だった場合ツールチップ無しで表示 -->
                <div v-if="item.incidental">
                  <div>
                    {{ item.itemCd }}<br />
                    {{ item.incidental }}<br />
                    {{ item.itemName }}
                  </div>
                </div>
                <div v-else>
                  <div>
                    {{ item.itemCd }}<br />
                    {{ item.itemName }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- ロット/S/N -->
          <template v-slot:[`item.lot`]="{ item }">
            <!-- ロット／S/N  改行&ツールチップ -->
            <div v-if="item.lot.length > 15 || item.dueDate.length > 15">
              <!-- S/Nが9文字以上だった場合ツールチップで表示 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-truncate" style="max-width: 300px" v-bind="attrs" v-on="on">
                    {{ item.lot }}<br />
                    {{ item.dueDate }}
                  </div>
                </template>
                <span>{{ item.lot }}<br />{{ item.dueDate }}</span>
              </v-tooltip>
            </div>
            <div v-else>
              <!-- S/Nが9文字以下だった場合ツールチップ無しで表示 -->
              {{ item.lot }}<br />
              {{ item.dueDate }}
            </div>
          </template>

          <template v-slot:[`item.productQty`]="{ item }">
            <!-- 予定数/実績数 -->
            <div>{{ item.scheduleStock }}</div>
            <div v-bind:class="{ isD: item.isNew }">{{ item.inStock }}</div>
          </template>
          <template v-slot:[`item.processDiv`]="{ item }">
            <!-- 処理区分 -->
            <div v-if="item.processDiv == 1">通常</div>
            <div v-if="item.processDiv == 2">返品</div>
          </template>

          <template v-slot:[`item.manager`]="{ item }">
            <div v-if="item.manager != null">
              <!--担当者  改行&ツールチップ -->
              <div v-if="item.manager.length > 9">
                <!-- 担当者が9文字以上だった場合ツールチップで表示 -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-truncate" style="max-width: 150px" v-bind="attrs" v-on="on">
                      {{ item.manager }}
                    </div>
                  </template>
                  <span>{{ item.manager }} </span>
                </v-tooltip>
              </div>
              <div v-else>
                <!-- 担当者が9文字以下だった場合ツールチップ無しで表示 -->
                {{ item.manager }}
              </div>
            </div>
          </template>

          <template v-slot:[`item.detail`]="{ item }">
            <div>
              <v-btn v-if="archiveEditRole == 'true'" small @click="openUpdateDelete(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </template>
          <!-- * -->
        </v-data-table>

        <div class="text-center pt-2">
          <!-- ページングネーション -->
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <!-- メニュー -->
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="updateAchieve"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />

      <!-- 結果確認モーダル -->
      <v-dialog v-model="simpleDialog" persistent="" no-click-animation>
        <SimpleDialog
          :isShow.sync="simpleDialog"
          :title="infoDialog.title"
          :message="infoDialog.message"
          :homePageFlag="infoDialog.homePageFlag"
          :mngComSchFlg="infoDialog.mngComSchFlg"
          :firstPageFlag="infoDialog.firstPageFlag"
        />
      </v-dialog>

      <!-- 作業履歴更新モーダル -->
      <v-dialog v-model="isOpenWorkHistory" :max-width="1200">
        <WorkHistory
          :isOpenDetailDialog.sync="isOpenWorkHistory"
          :workHistoryInfo="workHistoryInfo"
          :okAction="init"
          @click="getEntHistoryList"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import WorkHistory from "../common/WorkHistoryUpdate";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import ConfirmDialog from "@/components/ConfirmDialog";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import SimpleDialog from "@/components/SimpleDialog";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";

export default {
  name: appConfig.SCREEN_ID.P_MNG_002, //"ReceivingHistory",
  components: {
    Loading,
    sideMenu,
    SimpleDialog,
    WorkHistory,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    loadingCounter: 0,
    // 作業履歴更新POPUPに渡す値
    workHistoryInfo: {
      inListSubSid: "",
      suppliersSelected: "",
      procCd: "",
      itemCd: "",
      lotNo: "",
      scheduleDate: "",
      screenId: "",
      workHistoryInfo: "",
      page: "",
    },
    checkFlg: false,
    u_10: false,
    // メニュー
    openMenu: null,
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 総件数
    totalCount: 0,
    // 取引先
    suppliersSelected: "",
    // 入荷予定日
    arrivalScheduleDate: "",
    // 画面表示用ﾘｽﾄ
    inputList: [],
    // API取得結果ﾘｽﾄ
    resultList: [],
    // 作業履歴更新ダイアログ
    isOpenWorkHistory: false,
    // 予定実績対比画面の値
    serchClientSid: "",
    serchReferenceDateCal: "",
    // 編集用チェックボックスリスト
    chengeListCheckbox: [],
    listCheckbox: [],
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
    },
    simpleDialog: false,
    // メッセージダイアログ
    infoDialog: {
      title: "",
      message: "",
      mngComSchFlg: false,
      homePageFlag: false,
      firstPageFlag: false,
    },
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 入力チェックエラーフラグ
    errorFlg: true,
    // ソート
    sortItem: "",
    sortOptions: {},
    // 実績修正権限
    archiveEditRole: "true",
    //作業ステータス
    workingStatus: "01",
    // 作業ステータスドロップダウン
    workingStatusList: [],
    // ヘッダ
    headerItems: [
      {
        // 選択
        text: i18n.tc("label.lbl_choice"),
        value: "check",
        width: "4%",
        sortable: false,
        align: "center",
      },
      // No
      {
        text: "No",
        value: "No",
        width: "8%",
        align: "left",
        sortable: false,
      },
      // 予定日
      {
        text: i18n.tc("label.lbl_schDate"),
        value: "arrivalScheduleDate",
        width: "3%",
        align: "left",
        sortable: false,
      },
      // 予定数
      {
        text: i18n.tc("label.lbl_scheduleQty"),
        value: "inScheduleQuantity",
        width: "6%",
        align: "right",
        sortable: false,
      },
      // 入荷元
      {
        text: i18n.tc("label.lbl_arrival"),
        value: "arrivalFrom",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "product",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "21%",
        align: "left",
        sortable: false,
      },
      // 予定数実績数
      {
        value: "productQty",
        width: "6%",
        align: "right",
        sortable: false,
      },

      // ステータス
      {
        text: i18n.tc("label.lbl_status"),
        value: "status",
        width: "9%",
        align: "left",
        sortable: false,
      },
      // 品質区分
      {
        text: i18n.tc("label.lbl_arrivalStatus"),
        value: "qualityDivName",
        width: "8%",
        align: "left",
        sortable: false,
      },
      // 処理区分
      {
        text: i18n.tc("label.lbl_processingdivision"),
        value: "processDiv",
        width: "8%",
        align: "left",
        sortable: false,
      },
      // 開始時刻
      {
        text: i18n.tc("label.lbl_startTime"),
        value: "startTime",
        width: "3%",
        align: "center",
        sortable: false,
      },
      // 終了時刻
      {
        text: i18n.tc("label.lbl_endTime"),
        value: "inEndTime",
        width: "3%",
        align: "center",
        sortable: false,
      },
      // 担当
      {
        text: i18n.tc("label.lbl_popDestinationNm"),
        value: "manager",
        align: "left",
        width: "6%",
        sortable: false,
      },
      // 詳細TODO:編集にする
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "detail",
        width: "3%",
        align: "center",
        sortable: false,
      },
    ],
  }),

  created() {
    // 予定実績対比画面からの値を設定する。

    // 予定実績対比画面の検索条件の値
    // 取引先SID
    this.serchClientSid = this.$route.params.serchClientSid;
    // 基準日カレンダー
    this.serchReferenceDateCal = this.$route.params.serchReferenceDateCal;

    // 選択行の値
    // 取引先SID
    this.clientSid = this.$route.params.clientSid;
    // 取引先名
    this.suppliersSelected = this.$route.params.clientName;
    // 入荷予定日（UTC）
    this.arrivalScheduleDateJ = this.$route.params.ReferenceDate;
    // 入荷予定日(画面用JST)

    this.arrivalScheduleDate = dateTimeHelper
      .convertUTC2JST(this.$route.params.ReferenceDate)
      .substr(0, 10);
    // 画面遷移判断フラグ
    this.checkFlg = this.$route.params.checkFlg;
  },

  methods: {
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.getEntHistoryList();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getEntHistoryList();
    },

    /**
     * 初期化
     */
    async init(screenFlag) {
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      if (screenFlag) {
        this.page = this.workHistoryInfo.page;
      }
      await this.getWorkingStautsPull();
      // 入庫履歴一覧取得
      this.getEntHistoryList();
      // 実績修正権限を取得
      this.archiveEditRole = sessionStorage.getItem("P-COM-006");
    },

    /**
     * 入荷履歴一覧GETAPI
     */
    getEntHistoryList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      // 取引先SID
      config.params.clientSid = this.clientSid;
      // // From日（yyyyMMdd 00:00:00.000）
      config.params.inDateFrom = this.arrivalScheduleDateJ;
      // // To日
      config.params.inDateTo = this.arrivalScheduleDate + " 14:59:59.999";
      // 入荷ステータス
      config.params.status =
        (this.workingStatus ?? "") === "" ? this.workingStatusList[0].value : this.workingStatus;

      // ページング
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageIndex = this.page;
      config.params.reqComPageLimit = this.itemsPerPage;

      // ソート
      if (this.sortItem != "") {
        config.params.reqComSortItem = this.sortItem;
      }
      // console.debug("getEntHistoryList() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_INHIST, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getEntHistoryList() Response", response);

            // 成功
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.pageCount = jsonData.resCom.resComPagination.totalPage;
              this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              let wareInHistList = [];
              // 初期化
              this.resultList = [];
              wareInHistList = jsonData.resIdv.inHist;

              for (var i = 0; i < wareInHistList.length; i++) {
                let listItem = wareInHistList[i];

                // 入荷予定数と入荷実績数が異なる場合、色変えフラグをtrue
                let isNewFlag = "";
                if (listItem.scheduleStock != listItem.inSignStock) {
                  isNewFlag = true;
                } else {
                  isNewFlag = false;
                }

                let inputListItem = {};
                // No
                inputListItem.No = listItem.inListNo;
                if (listItem.inScheduleDate != null) {
                  // 予定日
                  inputListItem.arrivalScheduleDate = dateTimeHelper
                    .convertUTC2JST(listItem.inScheduleDate)
                    .substr(0, 10);
                }
                if (listItem.inScheduleQuantity != null) {
                  // 予定数（入荷）
                  let inScheduleQuantity = listItem.inScheduleQuantity.slice(0, -3);
                  inputListItem.inScheduleQuantity = commonUtil.formatToCurrency(
                    Number(inScheduleQuantity)
                  );
                }
                // 入荷予定SID
                inputListItem.inListSid = listItem.inListSid;
                // 入荷予定No
                inputListItem.inListNo = listItem.inListNo;
                // 入荷元SID
                inputListItem.fromSid = listItem.fromSid;
                // 品番
                inputListItem.itemCd = listItem.itemCd.substring(0, 50).trim();
                inputListItem.itemCdShow = listItem.itemCd;
                //付帯
                inputListItem.incidental = listItem.itemCd.slice(-50).trim();
                // ロットNo、S/N
                let lotNoObj = commonUtil.convertLotNo(listItem.lotNo);
                inputListItem.lotNo = listItem.lotNo;
                inputListItem.lot = lotNoObj.lot;
                inputListItem.dueDate = lotNoObj.dueDate;
                if (listItem.scheduleStock != null) {
                  // 予定数（明細）
                  let scheduleStock = listItem.scheduleStock.slice(0, -3);
                  inputListItem.scheduleStock = commonUtil.formatToCurrency(Number(scheduleStock));
                }

                // 格納先ロケーションSID
                inputListItem.inLocationSid = listItem.inLocationSid;
                if (listItem.inSignStock != null) {
                  // 実績数（明細）
                  let inStock = listItem.inSignStock.slice(0, -3);
                  inputListItem.inStock = commonUtil.formatToCurrency(Number(inStock));
                } else {
                  // 実績数（明細）
                  inputListItem.inStock = "0";
                }

                // 入荷実績ステータス
                inputListItem.inStatus = listItem.inStatus;
                // 入荷ステータス名
                inputListItem.status = listItem.inStatusName;
                // 入荷完了時刻
                if (listItem.inEndTime != null) {
                  inputListItem.inEndTime = dateTimeHelper
                    .convertUTC2JST(listItem.inEndTime)
                    .substr(0, 19)
                    .replaceAll(" ", "\n");
                }
                // 入荷実績数TODO
                inputListItem.inUserCd = listItem.inSignStock;

                // 商品ステータス
                inputListItem.qualityDiv = listItem.qualityDiv;
                // 入荷時刻
                if (listItem.inStartTime != null) {
                  inputListItem.startTime = dateTimeHelper
                    .convertUTC2JST(listItem.inStartTime)
                    .substr(0, 19)
                    .replaceAll(" ", "\n");
                }
                // ロケーションSID
                inputListItem.wareInLocationSid = listItem.inLocationSid;
                // 入庫ユーザーコード
                inputListItem.wareInUserCd = listItem.inUserCd;
                // 入荷ロケーション番号
                inputListItem.inLocationNo = listItem.inLocationNo;
                // 入荷元名
                inputListItem.arrivalFrom = listItem.fromName;
                // 品名
                inputListItem.itemName = listItem.itemName;
                // 商品ステータス名
                inputListItem.qualityDivName = listItem.qualityDivName;
                // 処理区分
                inputListItem.processDiv = listItem.processDiv;

                // 入荷ユーザー名
                inputListItem.manager = listItem.inUserName;

                // セル色変えフラグ
                inputListItem.isNew = isNewFlag;
                // チェックボックス
                inputListItem.check = false;
                // 入荷伝票明細SID
                inputListItem.inListSubSid = listItem.inListSubSid;
                // 予定更新日時
                inputListItem.updateDatetime = listItem.updateDatetime;
                // 明細更新日時
                inputListItem.updateDatetimeDtl = listItem.updateDatetimeDtl;
                // 実績修正権限
                inputListItem.archiveEditRole = this.archiveEditRole;
                this.resultList.push(inputListItem);
              }
              this.inputList = [];
              // 画面表示用にデータ修正
              for (var j = 0; j < this.resultList.length; j++) {
                if (0 < j) {
                  // 対象の行と前の行の予定Noを比較
                  if (this.resultList[j].inListSid == this.resultList[j - 1].inListSid) {
                    this.inputList.push(this.resultList[j]);
                    // 前の行と予定Noが同じな場合、空を設定
                    this.inputList[j].No = "";
                    this.inputList[j].arrivalScheduleDate = "";
                    this.inputList[j].inScheduleQuantity = "";
                    this.inputList[j].arrivalFrom = "";
                  } else {
                    // 上記以外の場合はそのまま設定
                    this.inputList.push(this.resultList[j]);
                  }
                } else {
                  // 最初の行はそのまま設定
                  this.inputList.push(this.resultList[j]);
                }
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve();
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          })
          .then(() => {
            // チェックがあるレコードに対して、再度チェックを活性化する
            for (var m = 0; m < this.resultList.length; m++) {
              for (var l = 0; l < this.listCheckbox.length; l++) {
                if (this.resultList[m].inListSubSid == this.listCheckbox[m].inListSubSid) {
                  this.resultList[m].check = true;
                }
              }
            }
          });
      });
    },

    /**
     * 戻るボタン処理
     */
    backbtn() {
      this.checkFlg = true;

      this.$router.push({
        name: appConfig.MENU_ID.P_MNG_COMP_SCHEDULE,
        params: {
          // 予定実績対比画面「検索条件」の値を保持する。
          // 取引先SID
          clientSid: this.clientSid,
          searchclientSid: this.serchClientSid,
          // 基準日
          referenceDate: this.$route.params.ReferenceDate,
          // 基準日カレンダー
          referenceDateCal: this.serchReferenceDateCal,
          arrivalScheduleDateJ: this.arrivalScheduleDateJ,
          // 画面遷移フラグ
          checkFlg: this.checkFlg,
        },
      });
    },

    /**
     * チェックボックス押下処理
     */
    checkBoxList(item, index) {
      if (!item.check) {
        // チェックを入れた場合

        // 編集用リスト
        this.chengeListCheckbox.push(item);

        // 実行用リスト
        this.listCheckbox.push(item);
      } else {
        // チェックを外した場合

        // 外したレコードの入荷明細SIDを取得
        let inputListInListSubSid = this.inputList[index].inListSubSid;

        // 実行用リストを初期化
        this.listCheckbox = [];

        // チェックを外したレコード以外を実行用リストに詰めなおす
        for (var i = 0; i < this.chengeListCheckbox.length; i++) {
          if (this.chengeListCheckbox[i].inListSubSid != inputListInListSubSid) {
            this.listCheckbox.push(this.chengeListCheckbox[i]);
          }
        }
        // 編集用リストも更新
        this.chengeListCheckbox = [];
        for (var j = 0; j < this.listCheckbox.length; j++) {
          this.chengeListCheckbox.push(this.listCheckbox[j]);
        }
      }
    },

    /**
     * メニュー処理
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 作業履歴更新POPUPに遷移
     */
    openUpdateDelete(item) {
      this.editedIndex = this.inputList.indexOf(item);

      // 取引先SID
      this.workHistoryInfo.suppliersSelected = this.clientSid;
      // 工程コード（100：入荷）
      this.workHistoryInfo.procCd = "100";
      // 品名
      this.workHistoryInfo.itemName = this.inputList[this.editedIndex].itemName;
      // 品番
      this.workHistoryInfo.itemCd = this.inputList[this.editedIndex].itemCdShow;
      // ロット
      this.workHistoryInfo.lotNo = this.inputList[this.editedIndex].lotNo;
      // 予定日
      this.workHistoryInfo.scheduleDate = this.inputList[this.editedIndex].arrivalScheduleDate;
      // 画面名
      this.workHistoryInfo.screenId = i18n.tc("label.lbl_recieveAchieveUpdateDeletepc");
      // ステータス
      this.workHistoryInfo.status = this.inputList[this.editedIndex].inStatus;
      // 入荷予定伝票No
      this.workHistoryInfo.inListSubSid = this.inputList[this.editedIndex].inListSubSid;
      // ページング
      this.workHistoryInfo.page = this.page;
      this.isOpenWorkHistory = true;
    },

    /**
     * 登録ボタン押下
     */
    rcvAchAdd() {
      // チェックボックス、存在チェック
      if (this.listCheckbox.length > 0) {
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.listCheckbox[i])
            this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_002_C");
          this.ConfirmDialog.isOpen = true;
          this.ConfirmDialog.screenFlag = true;
          this.ConfirmDialog.changeFlag = false;
        }
      } else {
        // 警告ダイアログ表示
        this.updateDialogMessage = true;
        this.errorFlg = false;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      }
    },

    /**
     * 実績登録POSTAPI接続TODO:API完了次第、細かいところ修正 フラグで詳細ボタンの表示非表示する
     */
    updateAchieve() {
      if (this.listCheckbox.length > 0) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const body = this.$httpClient.createRequestBodyConfig();

        //  画面ID
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MNG_002;
        // チェックリスト追加
        var bodyList = [];

        for (var i = 0; i < this.listCheckbox.length; i++) {
          // チェックを入れたレコードのみ設定
          var inputMapBara = {};
          // 予定更新日時
          inputMapBara.updateDatetime = this.listCheckbox[i].updateDatetime;
          // 明細更新日時
          inputMapBara.updateDatetimeDtl = this.listCheckbox[i].updateDatetimeDtl;
          // 入荷伝票SID
          inputMapBara.inListSid = this.listCheckbox[i].inListSid;
          // 入荷伝票明細SID
          inputMapBara.inListSubSid = this.listCheckbox[i].inListSubSid;

          bodyList.push(inputMapBara);
        }

        // 入荷履歴一覧に追加
        body.reqIdv.inSchedules = bodyList;
        // console.debug("updateAchieve() Body", body);

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_INHIST, body, appConfig.APP_CONFIG)
            .then((response) => {
              // console.debug("updateAchieve() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.simpleDialog = true;
                // ホームボタン
                this.infoDialog.homePageFlag = true;
                // 予定実績対比ボタン
                this.infoDialog.mngComSchFlg = true;
                this.infoDialog.firstPageFlag = false;
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.simpleDialog = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;
              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      } else {
        // 警告ダイアログ表示
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      }
    },
    // 再有無プルダウン
    getWorkingStautsPull() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 仮登録
      const dummyPull = getParameter.getCodeMst(appConfig.CODETYPE.WORKING_STATUS_DIV);
      return Promise.all([dummyPull])
        .then((result) => {
          // 画面の初期値を設定します。
          this.workingStatusList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    arrivalScheduleDateCal(val) {
      this.arrivalScheduleDate = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.getEntHistoryList();
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_MNG_002_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getEntHistoryList();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  pointer-events: none;
}
.overflow {
  overflow: hidden;
}
.toatlNum {
  margin-left: 10px;
}
.txt-center {
  margin: auto;
}
// 入荷履歴空白
.item-historySpacer {
  margin-left: 200px;
  margin-right: 250px;
}
.text-label2 {
  margin-top: 20px;
}

// ラベル
.text-historylabel {
  margin-top: 0px;
}
.link {
  text-decoration: none;
}

.isD {
  background-color: $color-difference !important;
  //background-color: $color-error !important;
}

#listData.v-data-table ::v-deep th {
  padding: 0 12px;
}
</style>
